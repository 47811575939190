import { BlurImage } from "@components/BlurImage";
import {
  IconBrandLinkedin,
  IconBrandTwitter,
  IconMail,
} from "@tabler/icons-react";
import Link from "next/link";
import React from "react";
import { Button } from "@components/ui/Button";

const navigation = {
  navigate: [
    {
      href: "/about",
      name: "About Tradewinds",
    },
    {
      name: "Opportunities",
      href: "/opportunities",
    },
    {
      href: "/events",
      name: "Events",
    },
    {
      href: "/contact",
      name: "Contact",
    },
    {
      href: "cdao-office-hours",
      name: "CDAO Acquisition Executive Office Hours",
    },
  ],

  resources: [
    {
      href: "/cdao-vehicles",
      name: "CDAO Vehicles",
    },
    {
      href: "/responsible-ai",
      name: "AI Ethics",
    },
    {
      name: "Blog",
      href: "/blog",
    },
  ],

  social: [
    {
      name: "Follow us on LinkedIn",
      href: "https://www.linkedin.com/company/tradewind-ai/",
      icon: (props: any) => <IconBrandLinkedin strokeWidth={1.25} />,
    },
    {
      name: "Connect on Twitter",
      href: "https://twitter.com/Tradewind_ai",
      icon: (props: any) => <IconBrandTwitter strokeWidth={1.25} />,
    },
    {
      name: "success@tradewindai.com",
      href: "mailto:success@tradewindai.com",
      icon: (props: any) => <IconMail strokeWidth={1.25} />,
    },
  ],
};

export const Footer = () => {
  return (
    <footer className="bg-neutral-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="flex flex-wrap flex-col md:flex-row justify-between items-start gap-y-14 w-full">
          <div className="shrink-0 flex flex-col gap-8">
            <div className="flex flex-wrap gap-8 items-center">
              <Link href="/" className="flex-shrink-0">
                <BlurImage
                  src={`/images/logo-white.svg`}
                  width="200"
                  height="100"
                  className="object-contain"
                />
              </Link>
            </div>
            <div className="flex space-y-6 flex-col">
              {navigation.social.map((item, idx) => (
                <a
                  key={idx}
                  href={item.href}
                  className="text-gray-500 hover:text-gray-400 flex space-x-2"
                >
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                  <span className="">{item.name}</span>
                </a>
              ))}
            </div>
          </div>
          <div className="md:px-10">
            <img
              alt="CDAO Logo"
              src="/images/logos/cdao-logo-primary-gray.svg"
              className="h-[4.5rem] w-auto"
            />
          </div>

          <div className="gap-8">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div className="[&>*+*]:mt-3">
                <h3 className="font-semibold leading-6 text-white">
                  Solutions
                </h3>
                <ul role="list" className="space-y-4">
                  {navigation.navigate.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-10 md:mt-0 [&>*+*]:mt-3">
                <h3 className="font-semibold leading-6 text-white">
                  Categories
                </h3>
                <ul role="list" className="space-y-4">
                  {navigation.resources.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-gray-400">
            &copy; 2023 Tradewinds AI. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

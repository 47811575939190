import { UserButton } from "@clerk/nextjs";
import { AccountLink } from "@components/AccountLink";
import { CustomLink } from "@components/CustomLink";
import { ScrollArea } from "@components/ui/ScrollArea";
import { IconChevronDown, IconMenu2 } from "@tabler/icons-react";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { twMerge } from "tailwind-merge";

export const MobileNav = ({ navItems, user }: any) => {
  const [open, setOpen] = useState(false);
  const item = {
    exit: {
      // opacity: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.3,
        deplay: 0.9,
      },
    },
    show: {
      height: "100vh",
      // opacity: 1,
      transition: { duration: 0.3, staggerChildren: 0.2 },
    },
    hidden: {
      // opacity: 0,
      height: 0,
    },
  };

  const childItems = {
    hidden: { x: "-2vw", opacity: 0, height: 0 },
    show: { x: 0, opacity: 1, height: "auto" },
  };

  const [openChild, setOpenChild] = useState<string | null>(null);

  const handleOpenChild = (name: string) => {
    if (openChild === name) {
      setOpenChild(null);
    } else {
      setOpenChild(name);
    }
  };

  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', open)
  }, [open])

  return (
    <>
      <IconMenu2 onClick={() => setOpen(!open)} className=" text-neutral-400" />
      <AnimatePresence>
        {open && (
          <motion.div
            variants={item}
            initial="hidden"
            animate="show"
            exit={`exit`}
            className="fixed inset-0 bg-neutral-900 z-50 flex flex-col pt-10 text-xl font-bold text-zinc-600  hover:text-zinc-800 transition duration-200"
          >
            <ScrollArea className="h-screen max-h-full pb-32" showGradient={false}>
              <div className="container mx-auto px-10 [&>*+*]:mt-10">
                <IoIosCloseCircleOutline
                  className="absolute right-8 -top-10 h-10 w-10 text-neutral-400"
                  onClick={() => setOpen(!open)}
                />
                {navItems.map((navItem: any, idx: number) => (
                  <>
                    {navItem.children ? (
                      <div>
                        <motion.button
                          variants={childItems}
                          onClick={() => handleOpenChild(navItem.name)}
                          key={`link=${idx}`}
                          className={twMerge(
                            "text-white font-medium flex items-center gap-2 mb-2 | hover:text-themecolor",
                          )}
                        >
                          {navItem.name} <IconChevronDown className="w-4 h-4" />
                        </motion.button>
    
                        {openChild === navItem?.name && (
                          <div className="py-4 px-10 bg-neutral-800 rounded-lg w-full flex flex-col items-start space-y-4">
                            {navItem.children.map((el: any, idx: number) => (
                              <CustomLink
                                key={`link=${el?.link}${idx}`}
                                href={el.link}
                              >
                                <motion.span
                                  variants={childItems}
                                  className="block text-white font-medium text-center | hover:text-themecolor"
                                >
                                  {el.name}
                                </motion.span>
                              </CustomLink>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      <CustomLink key={`link=${idx}`} href={navItem.link} className="flex">
                        <motion.span
                          variants={childItems}
                          className="block text-white font-medium"
                        >
                          {navItem.name}
                        </motion.span>
                      </CustomLink>
                    )}
                  </>
                ))}
                {!user?.user ? (
                  <Link
                    href="/sign-in"
                    className="xl:block relative font-bold px-6 py-2 bg-themecolor text-black transition duration-200 rounded-full mx-2"
                  >
                    Login
                  </Link>
                ) : (
                  <>
                    <hr className="opacity-50" />
                    <div className="flex flex-col gap-6">
                      <AccountLink type={`mobile`} />
                      <UserButton afterSignOutUrl="/" />
                    </div>
                  </>
                )}
              </div>
            </ScrollArea>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

import clsx from "clsx";
import Image from "next/image";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

interface IBlurImage {
  height?: any;
  width?: any;
  src?: string | any;
  objectFit?: any;
  className?: string | any;
  alt?: string | undefined;
  layout?: any;
  draggable?: boolean;

  [key: string]: any;
}

export const BlurImage = ({
  height,
  width,
  src,
  className,
  objectFit,
  alt,
  draggable = false,
  layout,
  ...rest
}: IBlurImage) => {
  const [isLoading, setLoading] = useState(true);
  return (
    <Image
      className={clsx(
        twMerge("transition duration-300", className),
        isLoading ? "blur-sm" : "blur-0"
      )}
      onLoadingComplete={() => setLoading(false)}
      src={src}
      width={width}
      height={height}
      loading="lazy"
      decoding="async"
      blurDataURL={src}
      layout={layout}
      draggable={draggable}
      alt={alt ? alt : "Avatar"}
      {...rest}
    />
  );
};

import { CustomLink } from "@components/CustomLink";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";

export const DesktopNav = ({ navItems }: any) => {
  const router = useRouter();

  const isActive = (link: any) => {
    // check if pathname contains currentPage
    if (router.pathname.includes(link)) {
      return true;
    }
    return false;
  };

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const variants = {
    open: {
      opacity: 1,
      y: "-1vh",
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
    closed: {
      opacity: 0,
      y: "2vh",
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  };
  return (
    <>
      {navItems?.map((navItem: any, idx: number) =>
        // if navItem has children
        // TODO: validateDOMNesting(...): <a> cannot appear as a descendant of <a>. (nested CustomLinks)
        navItem.children ? (
          <CustomLink
            href={navItem?.link}
            key={navItem?.link ?? `navItem-level1-${idx}`}
            className="hover:cursor-pointer relative group font-bold whitespace-nowrap"
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            onClick={navItem.onClick}
          >
            <AnimatePresence mode="wait">
              {open && (
                <motion.div
                  variants={variants}
                  initial="closed"
                  animate="open"
                  exit="closed"
                  className="border-neutral-700 absolute top-12 z-50 min-w-[14rem] max-w-[28rem] inset-x-0 divide-y divide-neutral-700 shadow shadow-cyan-400/[0.1] border bg-neutral-900 flex-col items-start hidden group-hover:flex rounded-md"
                >
                  {navItem.children.map((el: any) => (
                    <CustomLink
                      key={el.link}
                      href={el.link}
                      className="text-left w-full"
                    >
                      <motion.span
                        initial={{
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                        }}
                        transition={{
                          delay: 0.1 * idx,
                        }}
                        className={twMerge(
                          "block text-sm text-gray-300 hover:text-themecolor font-medium text-left px-6 py-4 w-full relative group/submenu overflow-hidden"
                        )}
                      >
                        {el.name}
                      </motion.span>
                    </CustomLink>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
            <span
              className={twMerge(
                "text-gray-300 hover:text-gray-50 px-4 xl:px-6 py-6 text-sm"
              )}
            >
              {navItem.name}
            </span>
          </CustomLink>
        ) : (
          <CustomLink
            key={navItem.link}
            href={navItem.link}
            className="text-gray-300 hover:text-gray-50 transition duration-200 px-4 xl:px-6 py-6 text-sm font-bold relative overflow-hidden group inline-block"
            onClick={navItem.onClick}
          >
            {navItem.name}
          </CustomLink>
        )
      )}
    </>
  );
};

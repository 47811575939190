import clsx from "clsx";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";

import { Navbar } from "@components/Navbar/Navbar";
import { Footer } from "@components/Footer";

export const Container = (props: any) => {
  const { children, className, ...customMeta } = props;
  const router = useRouter();

  const meta = {
    title: "Tradewinds | Discover AI Opportunities within the DoD",
    description: `Tradewinds is the DoD's framework for sourcing, funding, and developing solutions to challenges in the AI, digital, and data analytics space.`,
    type: "website",
    image: "https://tradewindai.com/banner.png",
    ...customMeta,
  };

  return (
    <>
      <Head>
        <title>{meta.title}</title>
        <meta name="robots" content="follow, index" />
        <meta content={meta.description} name="description" />
        <meta
          property="og:url"
          content={`https://tradewindai.com${router.asPath}`}
        />
        <link
          rel="canonical"
          href={`https://tradewindai.com${router.asPath}`}
        />
        <meta property="og:type" content={meta.type} />
        <meta property="og:site_name" content="tradewindai" />
        <meta property="og:description" content={meta.description} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:image" content={meta.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@tradewindai" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.image} />
      </Head>

      <main className={clsx("antialiased bg-neutral-900 scroll-smooth overflow-x-clip", className)}>
        <Navbar />
        {children}
        <Footer />
      </main>
    </>
  );
};

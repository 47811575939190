import { CustomLink } from "./CustomLink";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useUser } from "@clerk/nextjs";
import { trpc } from "@utils/trpc";
import { IconChevronDown } from "@tabler/icons-react";

export const AccountLink = ({
  type = "desktop",
}: {
  type: "desktop" | "mobile";
}) => {
  const user = useUser();
  if (!user) return null;

  const { data: dbUser } = trpc.user.me.useQuery();
  const [open, setOpen] = useState<boolean>(false);

  const isGov =
    dbUser?.userRole === "admin" || dbUser?.userRole === "government";

  const navItem = isGov
    ? {
        name: "Account",
        children: [
          {
            link: "https://acqbot.tradewindai.com",
            name: "Acqbot",
          },
          {
            link: "/marketplace",
            name: "Marketplace",
          },
        ],
      }
    : {
        name: "Account",
        children: [
          {
            link: "/dashboard",
            name: "Dashboard",
          },
          {
            link: "/dashboard/organization",
            name: "Your Organization",
          },
          {
            link: "/dashboard/submissions",
            name: "Your Submissions",
          },
        ],
      };
  const childItems = {
    hidden: { x: "-2vw", opacity: 0, height: 0 },
    show: { x: 0, opacity: 1, height: "auto" },
  };
  const transition = {
    duration: 0.3,
    ease: "easeInOut",
  };
  const variants = {
    open: {
      opacity: 1,
      y: "-1vh",
      transition,
    },
    closed: {
      opacity: 0,
      y: "2vh",
      transition,
    },
  };

  return type === "desktop" ? (
    <p
      className="cursor-default relative group font-bold whitespace-nowrap"
      onMouseOver={() => setOpen(true)}
      onMouseOut={() => setOpen(false)}
    >
      <AnimatePresence mode="wait">
        {open && (
          <motion.div
            variants={variants}
            initial="closed"
            animate="open"
            exit="closed"
            className="border-neutral-700 absolute top-16 right-0 z-50 min-w-[14rem] max-w-[24rem] divide-y divide-neutral-700 shadow shadow-cyan-400/[0.1] border bg-neutral-900 flex-col items-start hidden group-hover:flex rounded-md"
          >
            {navItem.children.map((el: any, idx: number) => (
              <CustomLink
                key={el.link}
                href={el.link}
                className="text-left w-full"
              >
                <motion.span
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  transition={{
                    delay: 0.1 * idx,
                  }}
                  className="block text-gray-300 hover:text-themecolor font-medium text-left px-6 py-4 w-full relative group/submenu overflow-hidden"
                >
                  {el.name}
                </motion.span>
              </CustomLink>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      <span className="flex items-center gap-2 text-gray-300 hover:text-gray-50 px-4 xl:px-6 py-6 text-sm">
        {navItem.name}{" "}
        <span className="flex items-center justify-center h-4 w-4 bg-white rounded-full">
          <IconChevronDown className="w-3 h-3 text-neutral-700" />
        </span>
      </span>
    </p>
  ) : (
    <div className="flex flex-col mb-4 w-full">
      <motion.button
        variants={childItems}
        onClick={() => setOpen(!open)}
        className="text-white font-medium flex items-center gap-2 mb-2 | hover:text-themecolor"
      >
        {navItem.name} <IconChevronDown className="w-4 h-4" />
      </motion.button>

      {open && (
        <div className="py-4 px-10 bg-neutral-800 rounded-lg w-full flex flex-col items-start space-y-4">
          {navItem.children.map((el, idx) => (
            <CustomLink key={el.link + idx} href={el.link}>
              <motion.span
                variants={childItems}
                className="block text-white font-medium text-center | hover:text-themecolor"
              >
                {el.name}
              </motion.span>
            </CustomLink>
          ))}
        </div>
      )}
    </div>
  );
};
